<template>
  <div>
    <Spinner :is-data-loaded="isDataLoaded" />

    <MainInfoRealEstate
      :keys="general_information"
      :is-data-loaded="isDataLoaded"
      :title="$t('general_information')"
      :show-link="true"
      :is-change-status="true"
      :can-delete="true"
    />
     <MainInfoRealEstate
      :keys="real_estate_information"
      :is-data-loaded="isDataLoaded"
      :title="$t('property_information')"
    />

    <b-card v-if="isDataLoaded">

      <b-row>
        <b-col cols="12">
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <h2
              :class="{'mb-2':!showLink}"
              class="text-primary"
            >
              {{ $t('property_specifications') }}
            </h2>

          </div>
        </b-col>
        <b-col
          v-for="(item,key,index) in info_fields  "
          v-if="key !== 'customer' && key !== 'actions' && key !== 'status'"
          :key="'item_'+index"
          cols="12"
          :md="customCols ? 6:4"
          :lg="customCols ? 6:3"
        >
          <h4 class="custom-line-clamp-one">
            {{ $t(key) }}
          </h4>
          <div v-if="Array.isArray(item)">
            <div>
              {{ $t('from') + ' ' + (item[0] || '-') }}
            </div>
            <div>
              {{ $t('to') + ' ' + (item[1] || '-') }}
            </div>
          </div>
          <template v-else>
            <p>
              {{ item || '-' }}
            </p>
          </template>
          <hr>
        </b-col>
      </b-row>
    </b-card>

    <MainInfoRealEstate
      :is-data-loaded="isDataLoaded"
      :keys="geographical_location"
      :title="$t('geographical_location')"
    />
    <!--    <MainInfoRealEstate-->
    <!--      :keys="additional_features"-->
    <!--      :title="$t('additional_features')"-->
    <!--    />-->

    <MainInfoRealEstate
      :is-data-loaded="isDataLoaded"
      :keys="ownership_information"
      :title="$t('ownership_information')"
      :custom-cols="true"
    />
    <b-card v-if="details && details.images">
      <h3 class="mb-2">
        {{ $t('images') }}
      </h3>
      <div
        class="real-estate-images"
      >
        <div
          v-for="(image,index) in details.images"
          :key="'image_'+index"

        >
          <div class="position-relative">
            <div class="box-img">
              <div class="ribbon-wrapper">
                <div v-if="index === 0" class="ribbon bg-primary"> {{ $t('thumbnail') }}</div>
              </div>
              <img
                :src="image.original_url"
                alt="Image"
                class="w-100 custom-object-fit"
              >
            </div>
          </div>
        </div>
      </div>
    </b-card>

      <b-row v-if="isDataLoaded">
        <b-col
          v-if="details && details.documents&& details.documents.length>0"
          cols="12"
          md="6"
        >
          <b-card>
            <h3 class="mb-2">
              {{ $t('attachments') }}
            </h3>
            <b-row>
              <b-col
                v-if="details && details.documents && details.documents.length>0"
                cols="6"
              >
                <h4 class="mb-1">
                  {{ $t('documents') }}
                </h4>
                <b-button
                  variant="success"
                  class="w-100"
                  @click="downloadMultiFileDoc(details.documents)"
                >
                  {{ $t('download') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col
          v-if="details && details.video"
          cols="12"
          md="6"
        >
          <b-card>
            <h3 class="mb-2">
              {{ $t('video') }}
            </h3>
            <b-row>
              <b-col
                v-if="details && details.video"
                cols="6"
              >
                <h4 class="mb-1">
                  {{ $t('video') }}
                </h4>
                <b-button
                  variant="success"
                  class="w-100"
                  @click="downloadVideo(details.video)"
                >
                  {{ $t('video') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

  </div>
</template>

<script>
import { BEmbed } from 'bootstrap-vue'
import MainInfoRealEstate from '@/components/MainInfoRealEstate.vue'
import Spinner from '@/components/Spinner.vue'

export default {
  components: {
    Spinner,
    MainInfoRealEstate,
    BEmbed,
  },
  data() {
    return {
      details: null,
      geographical_location: null,
      real_estate_information: null,
      general_information: null,
      additional_features: null,
      ownership_information: null,
      districts: null,
      isDataLoaded: false,
      info_fields: null,
    }
  },
  created() {
    if (this.$route.params.resourceId) {
      this.axios.get(`/real-estate/${this.$route.params.resourceId}?has_info_label=true`)
        .then(res => {
          this.details = res.data.data
          this.info_fields = res.data.data.info_fields_label
          this.general_information = _.pick(this.details, [
            'advertiser_name',
            'advertiser_email',
            'advertiser_phone',
            'title',
            // 'advertiser_character_label',
            'authorization_no',
            'authorization_no_authority',
            'updated_at',
            'expiration_date',
            'status_value',
            'status',
            'views',
            'description',
            // 'slug',
            // 'id',
            'the_sub_type_of_ad_label',
            'ad_type_value',
            'price_type_label',
          ])
          this.geographical_location = _.pick(this.details, [
            'region',
            'city',
            'district',
            'longitude',
            'latitude',
            // 'street_name',
          ])
          this.real_estate_information = _.pick(this.details, [
            'land_no',
            'type',
            'price',
            'published_at',
            'total_area',
            'number_of_units',
            'floor_no',
            'selling_meter_price',
            'construction_period',
            // 'real_estate_facade',
            'real_estate_facade_label',
            'limits_and_lengths_of_the_property',
            'stores_count',
            'parking_count',
            'floors_count',
            'rooms_count',
            'offices_count',
            'apartments_count',
            'rental_space',
            'is_rented',
            'current_rent_price',
            'remaining_month',
          ])
          // this.additional_features = _.pick(this.details, [
          // ])
          this.ownership_information = _.pick(this.details, [
            'is_negotiable_label',
            'no_bid_received_label',
            'extra_fields',
            // 'amenities',
          ])
          this.isDataLoaded = true
        })
    }
  },
  methods: {
    // getYouTubeVideoId(url) {
    //   const regex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?.*v=([^&]+)/i
    //   const match = url.match(regex)
    //
    //   if (match && match[1]) {
    //     return `https://www.youtube.com/embed/${match[1]}?rel=0`
    //   }
    //   return null
    // },
    downloadVideo(videoUrl) {
      this.downloadFile(videoUrl)
    },
    downloadMultiFileDoc(files) {
      files.forEach(file => {
        this.downloadFile(file)
      })
    },
    downloadFile(Url) {
      fetch(Url)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url

          // Adding timestamp to make filename unique
          const timestamp = new Date().getTime()
          link.download = `file_${timestamp}`
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
        })
        .catch(error => {
          console.error('Error downloading File:', error)
        })
    },
  },
}
</script>
